<template>
	<div>
		<van-nav-bar
			title="404"
			left-arrow
			class="qjc-nav-bar"
			fixed
			@click-left="$router.back()"
		/>
		
		<div class="notFound qjc-absolute qjc-fts-32">
			<p>頁面走丟了</p>
			<router-link class="qjc-c-primary" to="/">點擊返回首頁</router-link>
		</div>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar } from "vant"
	Vue.use(NavBar);
	
	export default{
		name: 'notFound',
		data (){
			return {}
		}
	}
</script>

<style lang="scss" scoped>
	.notFound{
		background: url(../../assets/images/404.png) no-repeat top center;
		background-size: 100% auto;
		width: 4.4rem;
		top: 50%;
		left: 50%;
		padding-top: 3.5rem;
		transform: translate(-50%,-50%);
		
		p{
			color: #9A9FB4;
			margin-bottom: 0.16rem;
		}
	}
</style>
